import { MODAL_OPEN } from '../actionTypes';
import { ModalType } from '../../types'

const defaultState = {
  modalType: "" as ModalType,
  data: {} as any,
};

type Modal = {
  modalType: "" | ModalType;
  data: any;
};

function modalReducer(state: Modal = defaultState, action) {
  switch (action.type) {
    case MODAL_OPEN:
      return {
        ...state,
        modalType: action.payload.modalType as ModalType,
        data: action.payload.data,
      };
    default:
      return state;
  }
}

export default modalReducer;
