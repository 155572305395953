import { createStore, combineReducers } from 'redux'
import sessionReducer from './reducer/sessionReducer'
import modalReducer from "./reducer/modalReducer"

const rootReducer = combineReducers({
  session: sessionReducer,
  modal: modalReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export default () => createStore(rootReducer)
