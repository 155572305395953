import { SET_USER } from '../actionTypes';

const defaultState = {
  user: null,
};

type Session = {
  user: any;
};

function sessionReducer(state: Session = defaultState, action) {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload };
    default:
      return state;
  }
}

export default sessionReducer;
