import React from 'react'
import { Provider } from 'react-redux'

import createStore from './src/state/createStore'
import 'isomorphic-fetch'

import { ApolloClient, InMemoryCache, ApolloLink, HttpLink, concat } from '@apollo/client'
import { ApolloProvider } from '@apollo/react-hooks'

const httpLink = new HttpLink({
  uri: process.env.GATSBY_AWS_APPSYNC_URL,
})

const tokenMiddleware = new ApolloLink(async (operation, forward) => {
  const headers = {
    'X-Api-Key': process.env.GATSBY_AWS_APPSYNC_API_KEY
  }
  operation.setContext({ headers });
  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(tokenMiddleware, httpLink),
})

const wrapWithProvider = ({ element }) => {
  const store = createStore()
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>{element}</Provider>
    </ApolloProvider>
  )
};

export default wrapWithProvider